import { OCR_RESULT, OCR_TYPE, OCR_VALIDATION } from './cases-ocr';
import { CategoryLinkedQuote, CategoryQuoteGroupAuthorization } from './categories';
import { Client } from './clients';
import { CustomFormat } from './custom-format';
import { Employee } from './employee';
import { ServerFormSectionType, ServerFormCondition, ServerFormQuestion } from './forms';
import { LocaleObject } from './locale';
import { MergeKeys } from './merge-keys';
import { Organization, OrganizationKeys, OrganizationKeysDefault } from './organizations';

// *************************************************************************
// Main types
// *************************************************************************

type CaseBase = {
    // Required keys
    id: string;
    internalId: string;
    shortId?: string;
    status: StatusListItem;
    organization: Organization;
    createdAt: Date;
    createdBy: Employee;
    category: {
        id: string;
        internalId: string;
        title: LocaleObject;
        description: LocaleObject;
        workflows: Array<CaseCategoryWorkflow>;
    };
    workflows: Array<CaseWorkflow>;
    // optional keys
    employees: Array<Omit<Employee, 'contacts'>>;
    clients: Array<Client>;
};
export type CaseKeys = { clients: boolean; employees: boolean };
export type CaseKeysDefault = { clients: true; employees: true };
export type Case<
    T extends Partial<CaseKeys> = CaseKeysDefault,
    U extends Partial<OrganizationKeys> = OrganizationKeysDefault
> = CustomFormat<
    Omit<CaseBase, 'organization'> & { organization: Organization<U> },
    MergeKeys<CaseKeysDefault, T>,
    | 'id'
    | 'internalId'
    | 'shortId'
    | 'createdAt'
    | 'status'
    | 'createdBy'
    | 'category'
    | 'organization'
    | 'workflows'
>;

export type CaseCategoryWorkflow = {
    status: StatusListItem;
    isEnabled: boolean;
    actions: Array<{
        title: LocaleObject;
        internalId: string;
    }>;
};

export type CaseClient = Omit<Client, 'cases'> & {
    role: CaseClientRole;
    isMainClient: boolean;
};

export type CaseWorkflow = {
    title: LocaleObject;
    internalId: string;
    isCompleted: boolean;
};

export enum CaseEventCategory {
    CLIENTS = 'clients',
    EMPLOYEES = 'employees',
    STATUS = 'status',
    QUOTES = 'quotes',
    FILES = 'files',
    NOTES = 'notes',
}

export type CaseEvent = {
    eventCategory: CaseEventCategory;
    author: string;
    locale: {
        fr: string;
        en: string;
    };
    timestamp: string;
};

export type CaseQuote = {
    id: string;
    category: CategoryLinkedQuote;
    client?: {
        id: string;
        internalId: string;
        firstName: string;
        lastName: string;
    };
    isAnonymous?: boolean;
    dependents?: Array<
        CaseQuote & {
            fullName: string;
        }
    >;
    fullName: string;
};

export type CaseFolder = {
    id: string;
    category: {
        id: string;
        index: number;
        title: LocaleObject;
    };
    folders: Array<CaseFolder_L1>;
    client?: {
        id: string;
        internalId: string;
        firstName: string;
        lastName: string;
    };
    isAnonymous?: boolean;
    isDependent?: boolean;
    dependents?: Array<CaseFolder>;
};

export type CaseFolder_L1 = {
    title: LocaleObject;
    authorization: CategoryQuoteGroupAuthorization;
    folders: Array<CaseFolder_L2>;
};

export type CaseFolder_L2 = {
    id: string;
    index: number;
    title: LocaleObject;
    description: LocaleObject;
    comment?: string;
    isDisplayed: boolean;
    isOCREnabled: boolean;
    isRequired: boolean;
    validatedAt?: Date;
    files: Array<CaseFile>;
};

export type CaseFile = {
    id: string;
    mimeType: string;
    originalName: string;
    size?: number;
    uploadedAt: Date;
    uploadedBy: {
        client?: string;
        employee?: string;
    };
    url?: {
        path: string;
        expiredAt: Date;
    };
    ocr?: {
        text: string;
        type?: OCR_TYPE;
        results?: Array<OCR_RESULT>;
        validations?: Array<OCR_VALIDATION>;
        executedAt: Date;
    };
};

export type CaseNote = {
    id: string;
    title: string;
    content: string;
    isVisibleForClient: boolean;
    createdAt: Date;
    createdBy: string;
};

export type CaseFormList = Array<{
    id: string;
    client?: {
        id: string;
        firstName: string;
        lastName: string;
    };
    form: {
        id: string;
        title: LocaleObject;
    };
}>;

export type CaseForm = {
    id: string;
    client?: {
        id: string;
        firstName: string;
        lastName: string;
    };
    form: {
        id: string;
        title: LocaleObject;
        sections: Array<CaseFormSection>;
        conditions: Array<ServerFormCondition>;
    };
};

export type CaseFormSection = {
    id: string; // Linked to form section
    internalId: string; // Unique case form section id
    name: string;
    index: number;
    page: number;
    sectionType: ServerFormSectionType;
    title: LocaleObject;
    description: LocaleObject;
    isDisplayed: boolean;
    questions: Array<CaseFormSectionQuestion>;
};

export type CaseFormSectionQuestion = ServerFormQuestion & {
    index: number;
    isDisplayed: boolean;
    answer: {
        value: string | number;
        validatedAt?: string;
    };
};

// *************************************************************************
// Atoms
// *************************************************************************

export enum CaseStatus {
    OPEN = 'open',
    QUOTE_SENT = 'quote_sent',
    QUOTE_PAID = 'quote_paid',
    INITIAL_SETUP = 'initial_setup',
    IN_PRODUCTION = 'in_production',
    IN_REVIEW = 'in_review',
    FILING = 'filing',
    FILED = 'filed',
    INTERVIEW_PREPARATION = 'interview_preparation',
    INTERVIEWED = 'interviewed',
    VISA_ISSUED = 'visa_issued',
    WORK_AUTHORIZATION = 'work_authorization',
    CLOSED = 'closed',
    ON_HOLD = 'on_hold',
}

export type StatusListItem = {
    id: CaseStatus;
    locale: LocaleObject;
};

export enum CaseClientRole {
    EMPLOYER = 'employer',
    EMPLOYEE = 'employee',
    ENTREPRENEUR = 'entrepreneur',
}

// *************************************************************************
// Payload and helpers
// *************************************************************************

export type CreateCasePayload = {
    categoryId: string;
    employees: Array<string>;
    organizationId?: string;
    clients: Array<{
        email: string;
        role: CaseClientRole;
    }>;
    newClients: Array<{
        email: string;
        role: CaseClientRole;
        firstName?: string;
        lastName?: string;
        password?: string;
        contacts?: Array<{ identifier?: string; phoneNumber: string }>;
    }>;
};

export type AddQuotesPayload = {
    quoteId: string;
    clientId?: string;
    dependents?: Array<{
        fullName: string;
    }>;
};

// *************************************************************************
// Helpers
// *************************************************************************

export type ExistingClientInformation = {
    email: string;
    role: CaseClientRole;
};

export type NewClientInformation = ExistingClientInformation & {
    firstName: string;
    lastName: string;
    password: string;
    phoneNumber: string;
};

export type ClientInformation = ExistingClientInformation | NewClientInformation;
