import React, { useMemo } from 'react';
import {
    MultiSelectInput,
    PageHeaderActions,
    PageHeaderActionsLeft,
    PageHeaderActionsRight,
    PageHeaderActionsRightButtons,
    PrimaryContainedAddButton,
    TextInput,
    UserAvatar,
    UserAvatarSize,
} from '../../../../../components';
import { useLocale, useLoggedInUser, useStore } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../../routes';
import { Category, Employee } from '../../../../../types';
import { MultiSelectInputOption } from '../../../../../components/Inputs/MultiSelectInput';
import { formatLocalizedText } from '../../../../../tools';

type Props = {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    selectedStatus: Array<string>;
    setSelectedStatus: React.Dispatch<React.SetStateAction<Array<string>>>;

    lawyers: Array<Employee>;
    selectedLawyers: Array<string>;
    setSelectedLawyers: React.Dispatch<React.SetStateAction<string[]>>;

    categories: Array<Category>;
    selectedCategories: Array<string>;
    setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
    isLoading: boolean;
};

const CasesListActions = ({
    searchTerm,
    setSearchTerm,
    selectedStatus,
    setSelectedStatus,

    lawyers,
    selectedLawyers,
    setSelectedLawyers,

    categories,
    selectedCategories,
    setSelectedCategories,
    isLoading,
}: Props) => {
    const locale = useLocale();
    const { t, i18n } = useTranslation();
    const { caseData } = useStore();
    const user = useLoggedInUser();

    const statusList = useMemo(() => {
        return caseData.statusList.map((item) => ({
            value: item.id,
            label: item.locale[i18n.language as 'en' | 'fr'],
        }));
    }, [t]);

    const lawyersList = useMemo(() => {
        const list: Array<MultiSelectInputOption<string, Employee>> = lawyers.map((lawyer) => ({
            value: lawyer.id,
            label: <UserAvatar {...lawyer} />,
            data: lawyer,
        }));
        return list;
    }, [lawyers]);

    const categoriesList = useMemo(() => {
        const list: Array<MultiSelectInputOption<string, Category>> = categories.map((category) => ({
            value: category.id,
            label: formatLocalizedText(category.title, locale),
        }));
        return list;
    }, [categories, locale]);

    return (
        <PageHeaderActions
            breakpoint={1270}
            grid={{
                default:
                    '300px minmax(200px, max-content) minmax(200px, max-content) minmax(200px, max-content)',
                mobile: [300, 300, 300, 300],
            }}
        >
            <PageHeaderActionsLeft>
                <TextInput
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    name="searchTerm"
                    placeholder={`${t('cases.search_case')}...`}
                />
                <MultiSelectInput
                    options={statusList}
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    label={t('generic.status')}
                    isSearchable={false}
                />
                <MultiSelectInput
                    options={categoriesList}
                    value={selectedCategories}
                    onChange={setSelectedCategories}
                    label={t('generic.category')}
                    isSearchable={false}
                />
                <MultiSelectInput
                    isSearchable={false}
                    isLoading={isLoading}
                    value={selectedLawyers}
                    onChange={setSelectedLawyers}
                    options={lawyersList}
                    renderOptionLabel={(_, lawyer) => <UserAvatar {...lawyer!} size={UserAvatarSize.SMALL} />}
                    label={t('generic.assigned_to')}
                    allOption={false}
                />
            </PageHeaderActionsLeft>
            <PageHeaderActionsRight>
                {user.hasPermissions('cases.create') && (
                    <PageHeaderActionsRightButtons>
                        <PrimaryContainedAddButton path={Routes.CASES_NEW}>
                            {t('cases.new_case.title')}
                        </PrimaryContainedAddButton>
                    </PageHeaderActionsRightButtons>
                )}
            </PageHeaderActionsRight>
        </PageHeaderActions>
    );
};

export default CasesListActions;
