import React from 'react';
import { Case, CaseFolder, CaseFolder_L2 } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import { useLocale, usePromises } from '../../../../../../hooks';
import { LocalizedText, Modal, ModalFooter } from '../../../../../../components';
import { formatCaseFolderDescription } from '../../../../../../tools';
import { List, ListItem, Message } from './UpdateRequiredFilesModal-styles';
import { APIService } from '../../../../../../services';

type Props = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    currentCaseFolder: CaseFolder;
    selectedFolders: Array<CaseFolder_L2>;
    areFoldersRequired: boolean;
};

const UpdateRequiredFilesModal = ({
    onClose,
    refresh,
    currentCase,
    currentCaseFolder,
    selectedFolders,
    areFoldersRequired,
}: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    const onSubmitHandler = async () => {
        await executePromise(async () => {
            await APIService.cases().updateRequiredFiles(currentCase.id, currentCaseFolder.id, {
                files: selectedFolders.map((folder) => ({ id: folder.id, isRequired: areFoldersRequired })),
            });
            await refresh();
            onClose();
        });
    };

    return (
        <Modal
            onClose={onClose}
            error={error}
            clearError={clearError}
            title={formatCaseFolderDescription(currentCaseFolder, locale)}
        >
            <Message>
                {t(
                    `case.documents.modals.update_required_files.message_${
                        areFoldersRequired ? 'required' : 'not_required'
                    }`
                )}
                :
            </Message>
            <List>
                {selectedFolders.map((selectedFolder) => (
                    <ListItem key={selectedFolder.id}>
                        <LocalizedText text={selectedFolder.title} />
                    </ListItem>
                ))}
            </List>
            <ModalFooter
                confirmationLabel={t(
                    `case.documents.modals.update_required_files.title_${
                        areFoldersRequired ? 'required' : 'not_required'
                    }`
                )}
                onClick={onSubmitHandler}
                isLoading={isExecutingPromise}
                secondary={{
                    onClick: onClose,
                }}
                isDestructive={areFoldersRequired !== true}
            />
        </Modal>
    );
};

export default UpdateRequiredFilesModal;
