import styled from 'styled-components';

export const Required = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin-left: ${({ theme }) => theme.box.spacing.xxs};
`;

export const FileCount = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-left: ${({ theme }) => theme.box.spacing.sm};
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.main};

    svg {
        margin-left: 3px;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.primary.extended_50};
    }
`;

export const Comment = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-left: ${({ theme }) => theme.box.spacing.sm};
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.main};

    &:hover {
        color: ${({ theme }) => theme.colors.primary.extended_50};
    }

    svg {
        margin-left: 3px;
    }
`;
