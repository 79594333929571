import React from 'react';
import { Case, CaseForm } from '../../../../../../types';
import { useLocale, usePromises } from '../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { APIService } from '../../../../../../services';
import { Modal, ModalFooter, ModalParagraph } from '../../../../../../components';
import { formatLocalizedText } from '../../../../../../tools';

type RemoveCaseFormModalProps = {
    onClose: () => void;
    currentCaseForm: CaseForm;
    currentCase: Case;
    refreshFormsList: () => Promise<void>;
    setCurrentCaseForm: React.Dispatch<React.SetStateAction<CaseForm | null>>;
};

const RemoveCaseFormModal = ({
    onClose,
    currentCaseForm,
    currentCase,
    refreshFormsList,
    setCurrentCaseForm,
}: RemoveCaseFormModalProps) => {
    const locale = useLocale();
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    /**
     * Submit handler
     */
    const submitHandler = async () => {
        await executePromise(async () => {
            await APIService.cases().removeForm(currentCase.id, currentCaseForm.id);
            await refreshFormsList();
            onCloseHandler();
        });
    };

    const onCloseHandler = () => {
        setCurrentCaseForm(null);
        onClose();
    };

    return (
        <Modal
            title={t('case.forms.modals.remove_form.title')}
            onClose={onCloseHandler}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>
                {t('case.forms.modals.remove_form.message', {
                    formName: formatLocalizedText(currentCaseForm.form.title, locale),
                })}
            </ModalParagraph>
            <ModalFooter
                confirmationLabel={t('case.forms.modals.remove_form.title')}
                onClick={submitHandler}
                secondary={{ onClick: onCloseHandler }}
                isLoading={isExecutingPromise}
                isDestructive
            />
        </Modal>
    );
};

export default RemoveCaseFormModal;
