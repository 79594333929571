import { IconType } from 'react-icons';
import {
    FaFilePdf,
    FaFileWord,
    FaFileImage,
    FaFileExcel,
    FaFileCsv,
    FaFilePowerpoint,
    FaFile,
} from 'react-icons/fa';

type SupportedFile = {
    extension: string;
    mimetype: string;
    Icon: IconType;
    color: string;
    settings: {
        enablePreview: boolean;
        enableOCR: boolean;
    };
};

enum SupportedFiles {
    PNG = 'png',
    JPG = 'jpg',
    JPEG = 'jpeg',
    PDF = 'pdf',
    DOC = 'doc',
    DOCX = 'docx',
    XLS = 'xls',
    XLSX = 'xlsx',
    PPT = 'ppt',
    PPTX = 'pptx',
    CSV = 'csv',
    UNKNOWN = 'unknown',
}

export const supportedFiles: Record<SupportedFiles, SupportedFile> = {
    [SupportedFiles.PNG]: {
        extension: '.png',
        mimetype: 'image/png',
        Icon: FaFileImage,
        color: 'orange',
        settings: {
            enablePreview: true,
            enableOCR: true,
        },
    },
    [SupportedFiles.JPG]: {
        extension: '.jpg',
        mimetype: 'image/jpg',
        Icon: FaFileImage,
        color: 'orange',
        settings: {
            enablePreview: true,
            enableOCR: true,
        },
    },
    [SupportedFiles.JPEG]: {
        extension: '.jpeg',
        mimetype: 'image/jpeg',
        Icon: FaFileImage,
        color: 'orange',
        settings: {
            enablePreview: true,
            enableOCR: true,
        },
    },
    [SupportedFiles.PDF]: {
        extension: '.pdf',
        mimetype: 'application/pdf',
        Icon: FaFilePdf,
        color: '#e30012',
        settings: {
            enablePreview: false,
            enableOCR: true,
        },
    },
    [SupportedFiles.DOC]: {
        extension: '.doc',
        mimetype: 'application/msword',
        Icon: FaFileWord,
        color: '#295394',
        settings: {
            enablePreview: false,
            enableOCR: false,
        },
    },
    [SupportedFiles.DOCX]: {
        extension: '.docx',
        mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        Icon: FaFileWord,
        color: '#295394',
        settings: {
            enablePreview: false,
            enableOCR: false,
        },
    },
    [SupportedFiles.XLS]: {
        extension: '.xls',
        mimetype: 'application/vnd.ms-excel',
        Icon: FaFileExcel,
        color: '#227045',
        settings: {
            enablePreview: false,
            enableOCR: false,
        },
    },
    [SupportedFiles.XLSX]: {
        extension: '.xlsx',
        mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Icon: FaFileExcel,
        color: '#227045',
        settings: {
            enablePreview: false,
            enableOCR: false,
        },
    },
    [SupportedFiles.PPT]: {
        extension: '.ptx',
        mimetype: 'application/vnd.ms-powerpoint',
        Icon: FaFilePowerpoint,
        color: '#e12929',
        settings: {
            enablePreview: false,
            enableOCR: false,
        },
    },
    [SupportedFiles.PPTX]: {
        extension: '.pptx',
        mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        Icon: FaFilePowerpoint,
        color: '#e12929',
        settings: {
            enablePreview: false,
            enableOCR: false,
        },
    },
    [SupportedFiles.CSV]: {
        extension: '.csv',
        mimetype: 'text/csv',
        Icon: FaFileCsv,
        color: '#227045',
        settings: {
            enablePreview: false,
            enableOCR: false,
        },
    },
    [SupportedFiles.UNKNOWN]: {
        extension: '',
        mimetype: '',
        Icon: FaFile,
        color: '#bec1d3',
        settings: {
            enablePreview: false,
            enableOCR: false,
        },
    },
};

export const getSupportedFilesByType = () => {
    const object = {} as { [key: string]: Array<string> };
    for (const { mimetype } of Object.values(supportedFiles)) {
        if (mimetype === '') {
            continue;
        }

        const type = mimetype.split('/')[0];
        if (object[type] != null) {
            object[type].push(mimetype);
        } else {
            object[type] = [mimetype];
        }
    }
    return object;
};

export const getSupportedFileByMimeType = (mimetype: string): SupportedFile => {
    let fileConfig = Object.values(supportedFiles).find(
        (supportedFile) => supportedFile.mimetype === mimetype
    );

    if (fileConfig != null) {
        return fileConfig;
    }

    return supportedFiles[SupportedFiles.UNKNOWN];
};
