import React from 'react';
import { CaseFolder_L2 } from '../../../../../../types';
import { useLocale } from '../../../../../../hooks';
import { Modal, ModalFooter, ModalSize, TextEditorInput } from '../../../../../../components';
import { formatLocalizedText } from '../../../../../../tools';

type ReadCommentModalProps = {
    onClose: () => void;
    selectedFolder: CaseFolder_L2;
};

const ReadCommentModal = ({ onClose, selectedFolder }: ReadCommentModalProps) => {
    const locale = useLocale();

    return (
        <Modal
            onClose={onClose}
            title={<>{formatLocalizedText(selectedFolder.title, locale)}</>}
            size={ModalSize.MEDIUM}
        >
            <TextEditorInput initialValue={selectedFolder.comment!} isDisabled />
            <ModalFooter confirmationLabel="OK" onClick={onClose} />
        </Modal>
    );
};

export default ReadCommentModal;
