import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale, usePromises } from '../../../../../../../../hooks';
import { CaseDocumentsFolderContext, ModalActions, MultipleSelectMode } from '../../CaseDocumentsFolder';
import {
    ClearSelection,
    CloseIcon,
    Container,
    Label,
    SelectAll,
} from './CaseDocumentsFolderMultiSelect-styles';
import { SecondaryOutlinedButton } from '../../../../../../../../components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaList } from 'react-icons/fa';
import { APIService } from '../../../../../../../../services';
import { CaseDocumentsContextType } from '../../../../CaseDocuments';
import { useOutletContext } from 'react-router-dom';

const CaseDocumentsFolderMultiSelect = () => {
    const { t } = useTranslation();
    const locale = useLocale();

    const { currentCase } = useOutletContext<CaseDocumentsContextType>();

    const {
        multipleSelectMode,
        selectedItemsCount,
        openModalHandler,
        closeModalHandler,
        setMultipleSelectMode,
        selectedFiles,
        caseFolder,
        isSelectingAll,
        selectAllFolderHandler,
        selectAllFileHandler,
    } = useContext(CaseDocumentsFolderContext);

    const [{ executePromise }] = usePromises();

    const multipleSelectList = useMemo(() => {
        if (multipleSelectMode === MultipleSelectMode.BY_FOLDERS) {
            return [
                {
                    label: t('case.documents.multiselect.validate_selections'),
                    onClick: () => openModalHandler(ModalActions.UPDATE_VALIDATED_FILES),
                },
                {
                    label: t('case.documents.multiselect.invalidate_selections'),
                    onClick: () => openModalHandler(ModalActions.UPDATE_INVALIDATED_FILES),
                },
                {
                    label: t('case.documents.multiselect.mark_as_required'),
                    onClick: () => openModalHandler(ModalActions.UPDATE_REQUIRED_FILES),
                },
                {
                    label: t('case.documents.multiselect.remove_requirement'),
                    onClick: () => openModalHandler(ModalActions.UPDATE_NOT_REQUIRED_FILES),
                },
            ];
        }

        if (multipleSelectMode === MultipleSelectMode.BY_FILES) {
            const list = [
                {
                    label: t('case.documents.multiselect.download_files'),
                    onClick: () => onClickDownloadFiles(),
                },
                {
                    label: t('case.documents.multiselect.move_files'),
                    onClick: () => openModalHandler(ModalActions.BATCH_UPDATE_FILES),
                },
                {
                    label: t('case.documents.multiselect.remove_files'),
                    onClick: () => openModalHandler(ModalActions.REMOVE_FILES),
                },
            ];

            if (
                selectedFiles.length > 1 &&
                selectedFiles.every((selectedFile) => selectedFile.mimeType === 'application/pdf')
            ) {
                list.push({
                    label: t('case.documents.multiselect.combine_files'),
                    onClick: () => openModalHandler(ModalActions.COMBINE_FILES),
                });
            }

            return list;
        }

        return undefined;
    }, [locale, multipleSelectMode, selectedFiles]);

    const mainList = useMemo(() => {
        return [
            {
                label: t('case.documents.multiselect.by_folders'),
                onClick: () => setMultipleSelectMode(MultipleSelectMode.BY_FOLDERS),
            },
            {
                label: t('case.documents.multiselect.by_files'),
                onClick: () => setMultipleSelectMode(MultipleSelectMode.BY_FILES),
            },
        ];
    }, [locale]);

    const onClickDownloadFiles = useCallback(async () => {
        await executePromise(async () => {
            const files = await Promise.all(
                selectedFiles.map(async (selectedFile) => {
                    const formattedFile = {
                        path: selectedFile.url?.path,
                        fileName: selectedFile.originalName,
                        blobUrl: null as null | string,
                    };

                    if (formattedFile.path == null) {
                        const response = await APIService.cases().getFileUrl(
                            currentCase.id,
                            caseFolder.id,
                            selectedFile.id
                        );
                        formattedFile.path = response.path;
                    }
                    const blobResponse = await fetch(formattedFile.path, {
                        method: 'GET',
                    });
                    const blob = await blobResponse.blob();
                    formattedFile.blobUrl = URL.createObjectURL(blob);
                    return formattedFile;
                })
            );

            for (const file of files) {
                if (file.blobUrl == null) {
                    continue;
                }
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = file.blobUrl;
                link.download = file.fileName; // Force the download with originalName

                // Append the link to the document
                document.body.appendChild(link);

                // Programmatically click the link
                link.click();

                // Cleanup: Revoke the Blob URL and remove the link
                URL.revokeObjectURL(file.blobUrl);
                document.body.removeChild(link);
            }
        });
    }, [selectedFiles]);

    const onClickSelectAll = () => {
        if (multipleSelectMode === MultipleSelectMode.BY_FOLDERS) {
            selectAllFolderHandler(!isSelectingAll);
        } else {
            selectAllFileHandler(!isSelectingAll);
        }
    };

    return (
        <>
            {multipleSelectMode != null && (
                <>
                    <ClearSelection
                        onClick={(e) => {
                            e.stopPropagation();
                            closeModalHandler();
                        }}
                    >
                        {t('case.documents.clear_selection')}
                    </ClearSelection>
                    <SelectAll
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickSelectAll();
                        }}
                    >
                        {isSelectingAll ? t('case.documents.unselect_all') : t('case.documents.select_all')}
                    </SelectAll>
                </>
            )}
            <Container isSelectingMultiple={multipleSelectMode != null} onClick={(e) => e.stopPropagation()}>
                {multipleSelectMode != null ? (
                    <SecondaryOutlinedButton list={multipleSelectList}>
                        <CloseIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                setMultipleSelectMode(null);
                            }}
                            className="close-icon"
                        >
                            <AiOutlineCloseCircle />
                        </CloseIcon>
                        <Label>{t('generic.selected', { count: selectedItemsCount })}</Label>
                    </SecondaryOutlinedButton>
                ) : (
                    <SecondaryOutlinedButton
                        leadingIcon={<FaList />}
                        list={mainList}
                        listRightSide
                        listHideToggleIcon
                    />
                )}
            </Container>
        </>
    );
};

export default CaseDocumentsFolderMultiSelect;
