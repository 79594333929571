import React from 'react';
import { useLoggedInUser, useStore } from '../../hooks';
import { Navigate } from 'react-router-dom';
import { Routes } from '../../routes';
import DashboardTable from './DashboardTable';
import { OutletContainer } from '../../components';
import { Case } from '../../types';

export type PAGE_DASHBOARD_Case = Case<{ clients: true; employees: false }, { assets: true }>;

const Dashboard = () => {
    const { clientCasesList } = useStore().caseData;
    const user = useLoggedInUser();

    if (user.isClient()) {
        const defaultCaseId = clientCasesList.at(-1)?.id;

        // TODO Handle client without case
        if (defaultCaseId != null) {
            return <Navigate to={`${Routes.CASES}/${defaultCaseId}/documents`} />;
        } else {
            return <React.Fragment />;
        }
    }

    return (
        <OutletContainer expand>
            <DashboardTable />
        </OutletContainer>
    );
};

export default Dashboard;
