import React, { useMemo, useState } from 'react';
import { Link, Outlet, useOutletContext } from 'react-router-dom';
import { CasesPagesContext } from '../CasePagesContext';
import {
    PageContainer,
    PageHeader,
    PrimaryContainedAddButton,
    PrimaryContainedButton,
    SelectInput,
} from '../../../../components';
import { CaseChildPageTitle } from '../CasePages-styles';
import { useTranslation } from 'react-i18next';
import { LuNewspaper } from 'react-icons/lu';
import { useLocale, useLoggedInUser, usePromises, useToggle } from '../../../../hooks';
import { Actions, CaseForm } from '../../../../types';
import { APIService } from '../../../../services';
import {
    AddCaseFormModal,
    RemoveCaseFormModal,
    UnsavedChangesModal,
    UpdateCaseFormDisplayModal,
} from './modals';
import { CaseChildPageTitleLink, CaseChildPageTitleSelect } from '../CaseDocuments/CaseDocuments-styles';
import { FiChevronRight } from 'react-icons/fi';
import { formatCaseFormTitle } from '../../../../tools';
import { Routes } from '../../../../routes';
import { FaFilePen } from 'react-icons/fa6';

export enum CustomActions {
    ADD_SECTIONS = 'add_sections',
    REMOVE_SECTIONS = 'remove_sections',
    UNSAVED_CHANGES = 'unsaved_changes',
}

const CaseForms = () => {
    const { currentCase } = useOutletContext<CasesPagesContext>();
    const locale = useLocale();
    const { t } = useTranslation();
    const user = useLoggedInUser();
    const [openedModal, openModal, closeModal] = useToggle<null | Actions | CustomActions>(null);
    const [promises, [caseForms]] = usePromises(() => APIService.cases().getFormsList(currentCase.id));
    const [currentCaseForm, setCurrentCaseForm] = useState<null | CaseForm>(null);

    const caseFormsList = useMemo(() => {
        if (caseForms.data == null) {
            return [];
        }

        return caseForms.data.map((form) => ({
            value: form.id,
            label: formatCaseFormTitle(form as any, locale),
            data: form,
        }));
    }, [caseForms.data, locale]);

    const refreshCurrentCaseForm = async () => {
        if (currentCaseForm == null) {
            return;
        }
        const form = await APIService.cases().getForm(currentCase.id, currentCaseForm.id);
        setCurrentCaseForm(form);
    };

    return (
        <>
            {openedModal === Actions.CREATE && (
                <AddCaseFormModal
                    currentCase={currentCase}
                    onClose={closeModal}
                    refreshFormsList={caseForms.refresh}
                />
            )}
            {openedModal === Actions.UPDATE && (
                <UpdateCaseFormDisplayModal
                    currentCase={currentCase}
                    onClose={closeModal}
                    refreshForm={refreshCurrentCaseForm}
                    currentCaseForm={currentCaseForm!}
                />
            )}

            {openedModal === CustomActions.UNSAVED_CHANGES && (
                <UnsavedChangesModal
                    currentCase={currentCase}
                    currentCaseForm={currentCaseForm!}
                    onClose={closeModal}
                />
            )}

            {openedModal === Actions.DELETE && (
                <RemoveCaseFormModal
                    currentCase={currentCase}
                    onClose={closeModal}
                    refreshFormsList={caseForms.refresh}
                    currentCaseForm={currentCaseForm!}
                    setCurrentCaseForm={setCurrentCaseForm}
                />
            )}

            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader display="row">
                    <CaseChildPageTitle>
                        <CaseChildPageTitleLink
                            to={`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_FORMS}`}
                        >
                            <LuNewspaper />
                            {currentCaseForm ? t('case.forms.all_forms') : t('case.forms.page_title')}
                        </CaseChildPageTitleLink>

                        {currentCaseForm != null && (
                            <CaseChildPageTitleSelect>
                                <FiChevronRight />
                                <SelectInput
                                    value={currentCaseForm.id}
                                    options={caseFormsList}
                                    onChange={() => ({})}
                                    renderOptionLabel={(value, data) => (
                                        <Link
                                            to={`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_FORMS}/${value}`}
                                        >
                                            {formatCaseFormTitle(data! as any, locale)}
                                        </Link>
                                    )}
                                />
                            </CaseChildPageTitleSelect>
                        )}
                    </CaseChildPageTitle>

                    {currentCaseForm == null && user.isEmployee() && (
                        <PrimaryContainedAddButton onClick={() => openModal(Actions.CREATE)}>
                            {t('case.forms.modals.add.title')}
                        </PrimaryContainedAddButton>
                    )}
                    {currentCaseForm != null && (
                        <PrimaryContainedButton
                            leadingIcon={<FaFilePen />}
                            path={`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_FORMS}/${currentCaseForm.id}/${Routes.CASE_FORMS_FILL}`}
                        >
                            {t('case.forms.modals.complete.title')}
                        </PrimaryContainedButton>
                    )}
                </PageHeader>

                <Outlet
                    context={{
                        currentCase: currentCase,
                        isInitializing: promises.isInitializing,
                        caseForms: caseForms.data ?? [],
                        currentCaseForm: currentCaseForm,
                        setCurrentCaseForm: setCurrentCaseForm,
                        executePromise: promises.executePromise,
                        openModal: openModal,
                        refreshCurrentCaseForm: refreshCurrentCaseForm,
                    }}
                />
            </PageContainer>
        </>
    );
};

export default CaseForms;
