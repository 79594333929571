import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.1;
`;

export const CheckBoxInputContainer = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.md};

    > label {
        > div.checkmark {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
`;

export const GoBack = styled(Link)`
    margin-right: ${({ theme }) => theme.box.spacing.sm};
    > svg {
        font-size: ${({ theme }) => theme.font.size.sm};
        color: ${({ theme }) => theme.colors.text.secondary};
    }

    :hover {
        > svg {
            color: ${({ theme }) => theme.colors.primary.main};
        }
    }
`;
