import styled from 'styled-components';

export const Container = styled.div<{ isSelectingMultiple: boolean }>`
    ${({ isSelectingMultiple, theme }) => {
        if (isSelectingMultiple === true) {
            return {
                button: {
                    background: theme.colors.primary.extended_10,
                },
            };
        }
    }}

    > button > div.close-icon > svg {
        font-size: ${({ theme }) => theme.font.size.lg};
        color: ${({ theme }) => theme.colors.danger.main} !important;
        fill: ${({ theme }) => theme.colors.danger.main} !important;
    }
`;

export const CloseIcon = styled.div`
    cursor: pointer;
    position: relative;
    z-index: 4;
`;

export const Label = styled.div`
    padding: 0 ${({ theme }) => theme.box.spacing.sm};
    color: ${({ theme }) => theme.colors.text.main};
`;

export const ClearSelection = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-right: ${({ theme }) => theme.box.spacing.md};
    color: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;

export const SelectAll = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-right: ${({ theme }) => theme.box.spacing.md};
    color: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;
