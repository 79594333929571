import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale, useLoggedInUser, usePromises, useScrollPosition } from '../../../../../../../../hooks';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import { APIService } from '../../../../../../../../services';
import { useOutletContext } from 'react-router-dom';
import { CaseDocumentsContextType } from '../../../../CaseDocuments';
import { CaseDocumentsFolderContext, ModalActions, MultipleSelectMode } from '../../CaseDocumentsFolder';

import { BsTrash, BsEye, BsThreeDots } from 'react-icons/bs';
import { IoIosMove } from 'react-icons/io';
import { BiPencil } from 'react-icons/bi';
import { MdOutlineError } from 'react-icons/md';
import { PiWarningFill } from 'react-icons/pi';
import { IoMdDownload } from 'react-icons/io';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import moment from 'moment';
import { formatBytes } from '../../../../../../../../tools';
import {
    CheckBoxContainer,
    Container,
    Content,
    ContentAsLink,
    ContentCta,
    ContentIcon,
    ContentIconLoading,
    ContentTitle,
    ContentTitleText,
    ContextMenu,
    ContextMenuDetails,
    ContextMenuDetailsItem,
    ContextMenuDetailsItemLabel,
    ContextMenuDetailsItemValue,
    ContextMenuItem,
    TooltipContent,
    TooltipContentIcon,
    TooltipContentText,
    TooltipIcon,
    Tooltips,
} from './CaseDocumentsFolderFile-styles';
import {
    CheckboxInput,
    FileIcon,
    FileIconSize,
    LocalizedText,
    Spinner,
    SpinnerAppearance,
} from '../../../../../../../../components';
import {
    CaseFileManager,
    CaseFolderManager_L2,
} from '../../../../../../../../managers/CaseFolderManager/modules';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { OCR_VALIDATION_SEVERITY } from '../../../../../../../../types';
import { getSupportedFileByMimeType } from '../../../../../../../../config/supported-files';

type CaseDocumentsFolderFileProps = {
    file: CaseFileManager;
    grandChildFolder: CaseFolderManager_L2;
};

const CaseDocumentsFolderFile = ({ file }: CaseDocumentsFolderFileProps) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const windowSize = useWindowSize();
    const scrollPosition = useScrollPosition();
    const containerRef = useRef<HTMLDivElement>(null);
    const user = useLoggedInUser();
    const { currentCase } = useOutletContext<CaseDocumentsContextType>();
    const {
        multipleSelectMode,
        selectedFiles,
        updateFileUrlHandler,
        openModalHandler,
        selectFileHandler,
        caseFolder,
    } = useContext(CaseDocumentsFolderContext);

    const [isGeneratingUrl, setIsGeneratingUrl] = useState(false);
    const [isContextMenuVisible, setContextMenuVisible] = useState<boolean>(false);
    const [contextMenuPosition, setContextMenuPosition] = useState<{ top: number; left: number }>({
        top: 0,
        left: 0,
    });

    // TODO handle error
    const [{ executePromise }] = usePromises();
    useOnClickOutside(containerRef, () => setContextMenuVisible(false));

    const enableOCR = useMemo(() => {
        const fileConfig = getSupportedFileByMimeType(file.mimeType);
        return fileConfig.settings.enableOCR === true;
    }, [file]);

    const isSelected = useMemo(
        () => selectedFiles.some((selectedFile) => selectedFile.id === file.id),
        [selectedFiles]
    );

    const { tooltipMessages, hasErrors, hasWarnings } = useMemo(() => {
        return {
            tooltipMessages: file.getTooltipMessages(),
            hasErrors: file.hasErrors(),
            hasWarnings: file.hasWarnings(),
        };
    }, [file]);

    const generateUrlHandler = async (): Promise<string | null> => {
        return await executePromise(async () => {
            setIsGeneratingUrl(true);
            const response = await APIService.cases().getFileUrl(currentCase.id, caseFolder.id, file.id);
            updateFileUrlHandler(file.id, response);
            setIsGeneratingUrl(false);
            return response.path;
        });
    };

    const onClickOpenLink = async () => {
        const path = file.url?.path ?? (await generateUrlHandler());
        if (path != null) {
            window.open(path, '_blank');
        }
    };

    const onClickDownloadLink = async () => {
        await executePromise(async () => {
            setIsGeneratingUrl(true);

            const path = file.url?.path ?? (await generateUrlHandler());

            if (path == null) {
                return;
            }

            const blobResponse = await fetch(path, {
                method: 'GET',
            });
            const blob = await blobResponse.blob();
            const blobUrl = URL.createObjectURL(blob);

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = file.originalName; // Force the download with originalName

            // Append the link to the document
            document.body.appendChild(link);

            // Programmatically click the link
            link.click();

            // Cleanup: Revoke the Blob URL and remove the link
            URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);

            setIsGeneratingUrl(false);
        });
    };

    const getPositionsConfig = () => {
        const rect = containerRef.current!.getBoundingClientRect();
        return {
            maxLeft: windowSize.width - rect.width - windowSize.width * 0.1,
            maxTop: windowSize.height + window.scrollY - rect.height - windowSize.height * 0.2,
            rect: rect,
        };
    };

    const handleContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        const { maxLeft, maxTop } = getPositionsConfig();
        setContextMenuPosition({
            left: Math.min(maxLeft, e.clientX),
            top: Math.min(maxTop, e.clientY + window.scrollY),
        });

        setContextMenuVisible(true);
    };

    const onCLickCta = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        const { maxLeft, maxTop, rect } = getPositionsConfig();

        setContextMenuPosition({
            left: Math.min(maxLeft, rect.x),
            top: Math.min(maxTop, rect.y + window.scrollY + rect.height * 0.35),
        });
        setContextMenuVisible(true);
    };

    const actionsList = useMemo(() => {
        const hasOCR = file.ocr?.results != null;
        return [
            {
                label: t('case.documents.view_file'),
                onClick: onClickOpenLink,
                Icon: BsEye,
            },
            {
                label: t('case.documents.download'),
                onClick: onClickDownloadLink,
                Icon: IoMdDownload,
            },
            {
                label: t('case.documents.modals.update.title'),
                onClick: () => openModalHandler(ModalActions.UPDATE_FILE, { files: [file] }),
                Icon: BiPencil,
            },
            {
                label: t('case.documents.move_file'),
                onClick: () => openModalHandler(ModalActions.BATCH_UPDATE_FILES, { files: [file] }),
                Icon: IoIosMove,
            },
            {
                label: hasOCR ? t('case.documents.view_ocr') : t('case.documents.launch_ocr'),
                onClick: () => openModalHandler(ModalActions.OCR, { files: [file] }),
                Icon: FaMagnifyingGlass,
                isDisplayed: user.isEmployee() && enableOCR === true,
            },
            {
                label: t('case.documents.modals.remove.title', { count: 1 }),
                onClick: () => openModalHandler(ModalActions.REMOVE_FILES, { files: [file] }),
                Icon: BsTrash,
            },
        ].filter((action) => action.isDisplayed !== false);
    }, [locale, file.ocr, enableOCR]);

    const detailsList = useMemo(() => {
        return [
            {
                label: t('case.documents.uploaded_by'),
                value: file.uploadedBy.client ?? file.uploadedBy.employee,
            },
            {
                label: t('generic.date'),
                value: moment(file.uploadedAt).format('YYYY-MM-DD - hh:mmA'),
            },
            {
                label: t('generic.size'),
                value: formatBytes(file.size) ?? '-',
            },
        ];
    }, [locale]);

    const renderTooltipIcon = () => {
        if (hasErrors === true) {
            return (
                <TooltipIcon className="error">
                    <MdOutlineError />
                </TooltipIcon>
            );
        }
        if (hasWarnings === true) {
            return (
                <TooltipIcon className="warning">
                    <PiWarningFill />
                </TooltipIcon>
            );
        }
        return <></>;
    };

    const renderTooltipContent = () => {
        return (
            <ReactTooltip id={file.id} place="top-end">
                <Tooltips>
                    {tooltipMessages.map(({ text, severity }, index) => {
                        const isCritical = severity === OCR_VALIDATION_SEVERITY.CRITICAL;
                        return (
                            <TooltipContent key={index}>
                                <TooltipContentIcon className={isCritical ? 'error' : 'warning'}>
                                    {isCritical ? <MdOutlineError /> : <PiWarningFill />}
                                </TooltipContentIcon>
                                <TooltipContentText>
                                    <LocalizedText text={text} />
                                </TooltipContentText>
                            </TooltipContent>
                        );
                    })}
                </Tooltips>
            </ReactTooltip>
        );
    };

    const renderContent = () => {
        return (
            <>
                <ContentIcon isGeneratingUrl={isGeneratingUrl}>
                    <FileIcon mimeType={file.mimeType} size={FileIconSize.LARGE} />
                    {isGeneratingUrl && (
                        <ContentIconLoading>
                            <Spinner appearance={SpinnerAppearance.PRIMARY} />
                        </ContentIconLoading>
                    )}
                </ContentIcon>
                <ContentTitle>
                    {renderTooltipIcon()}
                    <ContentTitleText>{file.originalName}</ContentTitleText>
                </ContentTitle>
                <ContentCta className="cta" onClick={onCLickCta}>
                    <BsThreeDots />
                </ContentCta>

                {tooltipMessages.length > 0 && renderTooltipContent()}
            </>
        );
    };

    const renderFile = () => {
        if (multipleSelectMode === MultipleSelectMode.BY_FILES) {
            return (
                <Content
                    onClick={() => selectFileHandler(file, !isSelected)}
                    isContextMenuVisible={isContextMenuVisible}
                    multipleSelectMode={multipleSelectMode}
                    isSelected={isSelected}
                    data-tooltip-id={file.id}
                >
                    <CheckBoxContainer onClick={(e) => e.stopPropagation()}>
                        <CheckboxInput
                            isChecked={isSelected}
                            onChange={(isChecked) => selectFileHandler(file, isChecked)}
                        />
                    </CheckBoxContainer>
                    {renderContent()}
                </Content>
            );
        }

        if (file.url?.path != null) {
            return (
                <ContentAsLink
                    href={file.url.path}
                    target="_blank"
                    isContextMenuVisible={isContextMenuVisible}
                    multipleSelectMode={multipleSelectMode}
                    isSelected={isSelected}
                    data-tooltip-id={file.id}
                >
                    {renderContent()}
                </ContentAsLink>
            );
        }

        return (
            <Content
                onClick={onClickOpenLink}
                isContextMenuVisible={isContextMenuVisible}
                multipleSelectMode={multipleSelectMode}
                isSelected={isSelected}
                data-tooltip-id={file.id}
            >
                {renderContent()}
            </Content>
        );
    };

    return (
        <Container onContextMenu={handleContextMenu} ref={containerRef} scrollPosition={scrollPosition.y}>
            {renderFile()}
            {isContextMenuVisible && (
                <ContextMenu
                    style={{ top: `${contextMenuPosition.top}px`, left: `${contextMenuPosition.left}px` }}
                >
                    {actionsList.map(({ label, onClick, Icon }, index) => (
                        <ContextMenuItem
                            key={index}
                            onClick={() => {
                                onClick();
                                setContextMenuVisible(false);
                            }}
                        >
                            <Icon />
                            {label}
                        </ContextMenuItem>
                    ))}
                    <ContextMenuDetails>
                        {detailsList.map((detail, index) => (
                            <ContextMenuDetailsItem key={index}>
                                <ContextMenuDetailsItemLabel>{detail.label}:</ContextMenuDetailsItemLabel>
                                <ContextMenuDetailsItemValue>{detail.value}</ContextMenuDetailsItemValue>
                            </ContextMenuDetailsItem>
                        ))}
                    </ContextMenuDetails>
                </ContextMenu>
            )}
        </Container>
    );
};

export default CaseDocumentsFolderFile;
