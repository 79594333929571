import React from 'react';
import { Case, CaseNote } from '../../../../../../types';
import { useTranslation } from 'react-i18next';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormSelectInput,
    FormTextInput,
    FormTextEditorInput,
    Modal,
    ModalSize,
} from '../../../../../../components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { APIService } from '../../../../../../services';
import { useLoggedInUser, usePromises } from '../../../../../../hooks';

type AddUpdateCaseNoteModalProps = {
    onClose: () => void;
    selectedNote?: CaseNote;
    currentCase: Case;
    refreshNotesList: () => Promise<void>;
};

const AddUpdateCaseNoteModal = ({
    onClose,
    selectedNote,
    refreshNotesList,
    currentCase,
}: AddUpdateCaseNoteModalProps) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise }] = usePromises();
    const user = useLoggedInUser();

    const validationSchema = Yup.object({
        title: Yup.string().required(t('generic.error.required_field')),
        content: Yup.string()
            .test('isNotEmpty', t('generic.error.required_field'), (value) => {
                // Custom validation function to check if content is not equal to "<p></p>\n"
                return value !== '<p></p>\n';
            })
            .required(t('generic.error.required_field')),
        isVisibleForClient: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            title: selectedNote?.title ?? '',
            content: selectedNote?.content ?? '',
            isVisibleForClient: selectedNote?.isVisibleForClient ?? user.isClient() ? 'true' : 'false',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload = {
                    title: submittedValues.title,
                    content: submittedValues.content,
                    isVisibleForClient: submittedValues.isVisibleForClient === 'true',
                };

                if (selectedNote == null) {
                    await APIService.cases().addNote(currentCase.id, payload);
                } else {
                    await APIService.cases().updateNote(currentCase.id, selectedNote.id, payload);
                }
                await refreshNotesList();
                onClose();
            });
        },
    });

    return (
        <Modal
            title={
                selectedNote == null
                    ? t('case.notes.modals.add_note.title')
                    : t('case.notes.modals.update_note.title')
            }
            onClose={onClose}
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="title" expand={user.isClient()}>
                    <FormGroupLabel>{t('generic.title')}</FormGroupLabel>
                    <FormTextInput type="text" placeholder={t('generic.title')} />
                </FormGroup>
                {user.isEmployee() && (
                    <FormGroup name="isVisibleForClient">
                        <FormGroupLabel>{t('generic.authorization')}</FormGroupLabel>
                        <FormSelectInput
                            options={[
                                {
                                    label: t('case.notes.authorization.employees_only'),
                                    value: 'false',
                                },
                                {
                                    label: t('case.notes.authorization.employees_and_clients'),
                                    value: 'true',
                                },
                            ]}
                        />
                    </FormGroup>
                )}

                <FormGroup name="content" expand>
                    <FormGroupLabel>{t('generic.content')}</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>
                <FormFooter
                    submitLabel={
                        selectedNote == null
                            ? t('case.notes.modals.add_note.title')
                            : t('case.notes.modals.update_note.title')
                    }
                    secondary={{ onClick: onClose }}
                />
            </Form>
        </Modal>
    );
};

export default AddUpdateCaseNoteModal;
