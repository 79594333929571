import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale, useLoggedInUser } from '../../../../../../../../hooks';
import { CaseDocumentsFolderContext, ModalActions, MultipleSelectMode } from '../../CaseDocumentsFolder';
import { sortLocalizedText } from '../../../../../../../../tools';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionHeaderContent,
    AccordionHeaderTitle,
    LocalizedText,
    PrimaryContainedButton,
    SecondaryOutlinedButton,
} from '../../../../../../../../components';
import CaseDocumentsFolderHeader from '../CaseDocumentsFolderHeader';
import { MdCheck, MdClose } from 'react-icons/md';
import { BiUpload } from 'react-icons/bi';
import { FaExclamationCircle } from 'react-icons/fa';
import CaseDocumentsFolderFiles from '../CaseDocumentsFolderFiles';
import { CaseFolderManager_L2 } from '../../../../../../../../managers/CaseFolderManager/modules';
import { FaRegComment, FaRegFile } from 'react-icons/fa';
import { Required, FileCount, Comment } from './CaseDocumentsFolderContent-styles';

const CaseDocumentsFolderContent = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const user = useLoggedInUser();
    const {
        caseFolder,
        selectedFolders,
        multipleSelectMode,
        initialQuery,
        selectFolderHandler,
        openModalHandler,
    } = useContext(CaseDocumentsFolderContext);

    const displayedChildFolders = useMemo(() => {
        return caseFolder.folders
            .filter((folder) => folder.folders.some((childFolder) => childFolder.isDisplayed))
            .sort((a, b) => sortLocalizedText(a.title, b.title, locale));
    }, [caseFolder]);

    const renderGrandChildFolder = (
        grandChildFolder: CaseFolderManager_L2,
        grandChildFolderIndex: number,
        childFolderIndex: number, // parent of grand child
        isAllowedToUpload: boolean
    ) => {
        const isGrandChildValidated = grandChildFolder.isValidated();
        const isGrandChildRequired = grandChildFolder.isMarkedAsRequired();
        const isGrandChildSelected = selectedFolders.some((selected) => selected.id === grandChildFolder.id);
        const isGrandChildToggled =
            (caseFolder.id === initialQuery.quote &&
                grandChildFolder.files.some((file) => file.id === initialQuery.file)) ||
            (multipleSelectMode === MultipleSelectMode.BY_FILES && grandChildFolder.files.length > 0);

        return (
            <Accordion
                key={grandChildFolder.id}
                level={2}
                index={grandChildFolderIndex}
                parentIndex={childFolderIndex}
                isToggled={isGrandChildToggled}
            >
                <AccordionHeader>
                    <AccordionHeaderTitle>
                        <CaseDocumentsFolderHeader
                            level={2}
                            isValidated={isGrandChildValidated}
                            hasErrors={grandChildFolder.hasErrors()}
                            hasWarnings={grandChildFolder.hasWarnings()}
                            isSelected={isGrandChildSelected}
                            onSelectFolder={(isChecked) => selectFolderHandler(grandChildFolder, isChecked)}
                            tooltips={[grandChildFolder.getTooltip()]}
                        >
                            <LocalizedText text={grandChildFolder.title} />

                            {isGrandChildRequired === true && (
                                <Required>{`[${t('generic.required').toUpperCase()}]`}</Required>
                            )}

                            {grandChildFolder.files.length > 0 && (
                                <FileCount>
                                    {grandChildFolder.files.length}
                                    <FaRegFile />
                                </FileCount>
                            )}

                            {grandChildFolder.comment != null && grandChildFolder.comment.length > 0 && (
                                <Comment
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openModalHandler(ModalActions.READ_COMMENT, {
                                            folders: [grandChildFolder],
                                        });
                                    }}
                                >
                                    1
                                    <FaRegComment />
                                </Comment>
                            )}
                        </CaseDocumentsFolderHeader>
                    </AccordionHeaderTitle>
                    <AccordionHeaderContent>
                        {user.isEmployee() && (
                            <>
                                <SecondaryOutlinedButton
                                    leadingIcon={isGrandChildValidated ? <MdClose /> : <MdCheck />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openModalHandler(
                                            isGrandChildValidated
                                                ? ModalActions.UPDATE_INVALIDATED_FILES
                                                : ModalActions.UPDATE_VALIDATED_FILES,
                                            { folders: [grandChildFolder] }
                                        );
                                    }}
                                >
                                    {isGrandChildValidated ? t('generic.invalidate') : t('generic.validate')}
                                </SecondaryOutlinedButton>

                                <SecondaryOutlinedButton
                                    leadingIcon={<FaRegComment />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openModalHandler(ModalActions.UPDATE_COMMENT, {
                                            folders: [grandChildFolder],
                                        });
                                    }}
                                >
                                    {t('generic.comments')}
                                </SecondaryOutlinedButton>

                                <SecondaryOutlinedButton
                                    leadingIcon={<FaExclamationCircle />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openModalHandler(
                                            isGrandChildRequired
                                                ? ModalActions.UPDATE_NOT_REQUIRED_FILES
                                                : ModalActions.UPDATE_REQUIRED_FILES,
                                            { folders: [grandChildFolder] }
                                        );
                                    }}
                                >
                                    {isGrandChildRequired
                                        ? t('case.documents.modals.update_required_files.title_not_required')
                                        : t('case.documents.modals.update_required_files.title_required')}
                                </SecondaryOutlinedButton>
                            </>
                        )}

                        {isAllowedToUpload && (
                            <PrimaryContainedButton
                                leadingIcon={<BiUpload />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openModalHandler(ModalActions.UPLOAD_FILE, {
                                        folders: [grandChildFolder],
                                    });
                                }}
                            />
                        )}
                    </AccordionHeaderContent>
                </AccordionHeader>
                <AccordionBody>
                    <CaseDocumentsFolderFiles
                        grandChildFolder={grandChildFolder}
                        isAllowedToUpload={isAllowedToUpload}
                    />
                </AccordionBody>
            </Accordion>
        );
    };

    return (
        <>
            {displayedChildFolders.map((childFolder, childFolderIndex) => {
                const isAllowedToUpload = user.isAllowedToUpload(childFolder);
                const isSelected = childFolder.folders.some((grandChildFolder) =>
                    selectedFolders.some((selectedFolder) => selectedFolder.id === grandChildFolder.id)
                );

                return (
                    <Accordion key={childFolderIndex} index={childFolderIndex} level={1} isToggled>
                        <AccordionHeader>
                            <AccordionHeaderTitle>
                                <CaseDocumentsFolderHeader
                                    level={1}
                                    isValidated={childFolder.isValidated()}
                                    hasErrors={childFolder.hasErrors()}
                                    hasWarnings={childFolder.hasWarnings()}
                                    isSelected={isSelected}
                                    onSelectFolder={(isChecked) =>
                                        selectFolderHandler(childFolder, isChecked)
                                    }
                                >
                                    <LocalizedText text={childFolder.title} />
                                </CaseDocumentsFolderHeader>
                            </AccordionHeaderTitle>
                        </AccordionHeader>
                        <AccordionBody>
                            {childFolder.folders
                                .sort((a, b) => a.index - b.index)
                                .filter((grandChildFolder) => grandChildFolder.isDisplayed)
                                .map((grandChildFolder, grandChildFolderIndex) =>
                                    renderGrandChildFolder(
                                        grandChildFolder,
                                        grandChildFolderIndex,
                                        childFolderIndex,
                                        isAllowedToUpload
                                    )
                                )}
                        </AccordionBody>
                    </Accordion>
                );
            })}
        </>
    );
};

export default CaseDocumentsFolderContent;
