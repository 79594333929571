import React from 'react';
import { usePromises } from '../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { Case, CaseFolder_L2 } from '../../../../../../types';
import { CaseFolderManager } from '../../../../../../managers';
import { APIService } from '../../../../../../services';
import { Modal, ModalFooter, ModalParagraph } from '../../../../../../components';

type Props = {
    onClose: () => void;
    currentCase: Case;
    currentCaseFolder: CaseFolderManager;
    selectedFolder: CaseFolder_L2;
    refresh: () => Promise<void>;
};

const RemoveCommentModal = ({ onClose, refresh, currentCase, currentCaseFolder, selectedFolder }: Props) => {
    const { t } = useTranslation();
    const [{ error, clearError, executePromise, isExecutingPromise }] = usePromises();

    /**
     * Submit handler
     */
    const submitHandler = async () => {
        await executePromise(async () => {
            await APIService.cases().updateCommentedFiles(currentCase.id, currentCaseFolder.id, {
                id: selectedFolder.id,
            });
            await refresh();
            onClose();
        });
    };

    return (
        <Modal
            title={t('case.documents.modals.remove_comment.title')}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <ModalParagraph>{t('case.documents.modals.remove_comment.message')}</ModalParagraph>
            <ModalFooter
                confirmationLabel={t('case.documents.modals.remove_comment.title')}
                onClick={submitHandler}
                secondary={{ onClick: onClose }}
                isLoading={isExecutingPromise}
                isDestructive
            />
        </Modal>
    );
};

export default RemoveCommentModal;
