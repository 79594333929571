import React from 'react';
import config from '../../config/main';
import { Container } from './FileIcon-styles';

export enum FileIconSize {
    SMALL,
    MEDIUM,
    LARGE,
}

type FileIconProps = {
    size?: FileIconSize;
    mimeType: string;
};

const FileIcon = ({ mimeType, size = FileIconSize.MEDIUM }: FileIconProps) => {
    const { Icon, color } =
        Object.values(config.supportedFiles).find((supportedFile) => supportedFile.mimetype === mimeType) ??
        config.supportedFiles.unknown;

    return Icon ? (
        <Container size={size}>
            <Icon fill={color} />
        </Container>
    ) : null;
};

export default FileIcon;
