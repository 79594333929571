import React from 'react';
import { Case, CaseFile, CaseFolder, CaseFolder_L2 } from '../../../../../../types';
import {
    Form,
    FormGroup,
    FormGroupLabel,
    FormTextEditorInput,
    Modal,
    FormFooter,
    ModalSize,
} from '../../../../../../components';
import { useLocale, usePromises } from '../../../../../../hooks';
import { formatLocalizedText } from '../../../../../../tools';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { APIService } from '../../../../../../services';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { RemoveContainer, RemoveLabel, RemoveLabelText } from './UpdateCommentModal-styles';
import { ModalActions } from '../../CaseDocumentsDetails/CaseDocumentsFolder/CaseDocumentsFolder';

type UpdateCommentModalProps = {
    onClose: () => void;
    refresh: () => Promise<void>;
    currentCase: Case;
    currentCaseFolder: CaseFolder;
    selectedFolder: CaseFolder_L2;
    openModalHandler: (
        selectedAction: ModalActions,
        payload?: { folders?: Array<CaseFolder_L2>; files?: Array<CaseFile> }
    ) => void;
};

const UpdateCommentModal = ({
    onClose,
    refresh,
    currentCase,
    currentCaseFolder,
    selectedFolder,
    openModalHandler,
}: UpdateCommentModalProps) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        content: Yup.string().notRequired(),
    });

    const formik = useFormik({
        initialValues: {
            content: selectedFolder.comment ?? '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const submittedContent = submittedValues.content.trim();
                const content = submittedContent === '<p></p>' ? '' : submittedContent;
                await APIService.cases().updateCommentedFiles(currentCase.id, currentCaseFolder.id, {
                    id: selectedFolder.id,
                    content: content,
                });
                await refresh();
                onClose();
            });
        },
    });

    return (
        <Modal
            onClose={onClose}
            error={error}
            clearError={clearError}
            title={formatLocalizedText(selectedFolder.title, locale)}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="content" expand>
                    <FormGroupLabel>{t('generic.comments')}</FormGroupLabel>
                    <FormTextEditorInput />
                </FormGroup>
                <FormFooter
                    submitLabel={t('generic.save')}
                    secondary={{
                        label: t('generic.cancel'),
                        onClick: onClose,
                    }}
                >
                    {selectedFolder.comment != null && selectedFolder.comment.length > 0 && (
                        <RemoveContainer>
                            <RemoveLabel
                                onClick={() =>
                                    openModalHandler(ModalActions.REMOVE_COMMENT, {
                                        folders: [selectedFolder],
                                    })
                                }
                            >
                                <BsTrash />
                                <RemoveLabelText>
                                    {t('case.documents.modals.remove_comment.title')}
                                </RemoveLabelText>
                            </RemoveLabel>
                        </RemoveContainer>
                    )}
                </FormFooter>
            </Form>
        </Modal>
    );
};

export default UpdateCommentModal;
