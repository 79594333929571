import React from 'react';
import { Table, TableBodyRowAction, TableColumnConfig } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import { CasesFormsPagesContext } from '../../CaseFormsContext';
import { useOutletContext } from 'react-router-dom';
import { useLocale, useLoggedInUser, useTable } from '../../../../../../hooks';
import { Actions, CaseForm, CaseFormList } from '../../../../../../types';
import { formatCaseFormTitle, formatLocalizedText } from '../../../../../../tools';
import { Form, FormLink } from './CaseFormsList-styles';
import { SiGoogleforms } from 'react-icons/si';
import { Routes } from '../../../../../../routes';

const CaseFormsList = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const { currentCase, isInitializing, caseForms, openModal, setCurrentCaseForm } =
        useOutletContext<CasesFormsPagesContext>();
    const user = useLoggedInUser();

    const columns: Array<TableColumnConfig<CaseFormList[number]>> = [
        {
            id: 'title',
            headerTitle: t('generic.title'),
            width: '1fr',
            getValue: (form) => formatLocalizedText(form.form.title, locale),
            getDisplayedValue: (form) => (
                <Form>
                    <SiGoogleforms />
                    <FormLink to={`/${Routes.CASES}/${currentCase.id}/${Routes.CASE_FORMS}/${form.id}`}>
                        {formatCaseFormTitle(form as any, locale)}
                    </FormLink>
                </Form>
            ),
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            getDisplayedValue: (form, { index, total }) => (
                <TableBodyRowAction
                    positionListAtTop={index > 0 && index >= total - 2}
                    actions={[
                        {
                            label: t('case.forms.modals.remove_form.title'),
                            onClick: () => {
                                setCurrentCaseForm(form as CaseForm);
                                openModal(Actions.DELETE);
                            },
                        },
                    ]}
                />
            ),
            width: '80px',
            isDisplayed: user.isEmployee(),
        },
    ];

    const table = useTable(caseForms ?? [], columns);

    return <Table {...table} columns={table.displayedColumns} isLoading={isInitializing} />;
};

export default CaseFormsList;
