import styled from 'styled-components';

export const RemoveContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
`;

export const RemoveLabel = styled.div`
    color: ${({ theme }) => theme.colors.danger.main};
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.md};

    svg {
        margin-right: ${({ theme }) => theme.box.spacing.sm};
    }
`;

export const RemoveLabelText = styled.div`
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;
